import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">Created with ♥ by <b>Rational-X</b> 2020</span>
  <small style="color: white;">[ {{version}} ]</small>
  `,
})
export class FooterComponent {

  get version() {
    return environment.version;
  }
}
