<div *ngIf="label" class="ngx-markdown-editor-label">
  {{ label }} <span class="required-marker">{{ required ? '*' : '' }}</span>
</div>
<div *ngIf="showToolbar" class="ngx-markdown-editor-toolbar">
  <ng-container *ngFor="let item of normalizedToolbarItems; index as i" [ngSwitch]="item.name">
    <ng-template [ngSwitchCase]="'|'">
      <div class="mde-toolbar-separator"></div>
    </ng-template>

    <ng-template [ngSwitchCase]="'setHeadingLevel'">
      <mat-form-field floatLabel="never" [matTooltip]="toolbarItemTooltips[i]">
        <mat-label>
          <mat-icon *ngIf="item.icon.format === 'material'">
            {{ item.icon.iconName }}
          </mat-icon>
          <mat-icon *ngIf="item.icon.format !== 'material'" [svgIcon]="item.icon.iconName"> </mat-icon>
        </mat-label>
        <div (mousedown)="blockBlur = true">
          <mat-select
            [value]="activeToolbarItems[i]"
            [disabled]="disabled || (showPreview && item.disableOnPreview)"
            (selectionChange)="item.action($event.value)"
            (closed)="mde.cm.focus(); blockBlur = false"
            #setHeadingLevel
          >
            <mat-option value="0">---</mat-option>
            <mat-option *ngFor="let j of [1, 2, 3, 4, 5, 6]" [value]="j">H{{ j }}</mat-option>
          </mat-select>
        </div>
      </mat-form-field>
    </ng-template>

    <ng-template ngSwitchDefault>
      <button
        mat-button
        (mousedown)="blockBlur = true"
        (mouseup)="blockBlur = false"
        (click)="onButtonClick(item)"
        [matTooltip]="toolbarItemTooltips[i]"
        [class.active]="activeToolbarItems[i]"
        [disabled]="disabled || (showPreview && item.disableOnPreview)"
      >
        <mat-icon *ngIf="item.icon.format === 'material'">
          {{ item.icon.iconName }}
        </mat-icon>
        <mat-icon *ngIf="item.icon.format !== 'material'" [svgIcon]="item.icon.iconName"> </mat-icon>
      </button>
    </ng-template>
  </ng-container>
</div>
<div class="ngx-markdown-editor-wrapper" tabindex="-1">
  <div [ngStyle]="{ display: showPreview ? 'none' : '' }" class="ngx-markdown-editor-text-editor"></div>
  <div [ngStyle]="{ display: showPreview || showSideBySidePreview ? '' : 'none' }" class="ngx-markdown-editor-preview">
    <div class="preview-wrapper">
      <markdown [data]="mde.getContent()" (ready)="replaceCheckboxDummies()" #markdown></markdown>
    </div>
  </div>
</div>
<div *ngIf="showStatusbar" class="ngx-markdown-editor-statusbar">
  <ng-container *ngFor="let item of normalizedStatusbarItems" [ngSwitch]="item.name">
    <div *ngSwitchCase="'|'" class="mde-statusbar-separator"></div>
    <div *ngSwitchDefault class="mde-statusbar-item">{{ item.value | async }}</div>
  </ng-container>
</div>
